import * as React from "react";
import Menu from "./Menu"

const MobMenu: React.FC = () => {
  const [isActive, setisActive] = React.useState<boolean>(false);
  const toggleModal = () => {
    !isActive
      ? document.body.classList.add('open_menu')
      : document.body.classList.remove('open_menu')
    setisActive(!isActive)
  }
  const isActiveClass = isActive ? 'active' : ''; 

  return (
    <div className="mob_menu d-block d-sm-none">
      <span className={`mob_menu_hamburger ${isActiveClass}`} onClick={()=> toggleModal()}>
        <i></i>
        <i></i>
      </span>
      <section className={`mob_menu_side ${isActiveClass}`}>
        <div className="text-center d-block d-sm-none">
          <Menu toggleModal={() => toggleModal()}/>
        </div>
      </section>
      <i className="overlay" onClick={()=> toggleModal()}></i>
    </div>
  );
};

export default MobMenu;