import * as React from "react";
import { Link } from 'gatsby';

const Logo: React.FC = () => {
  return (
    <div className="my-2 d-flex navbar_logo">
      <Link to="/">
        <span style={{fontSize: "25px", position: "absolute", top: "-7px"}}>ArtCode</span>
      </Link>
    </div>
  );
};

export default Logo;
