import * as React from "react";
import Menu from "./Menu"

const Footer: React.FC = () => {
  return (
    <footer className="footer pt-5">
      <div className="container pt-5 pb-4 d-flex justify-content-center">
        <div className="text-center">
        <Menu/>
        <div className="follow_us pt-4 pb-5">
          <p>Follow us: 
            <a href="https://www.linkedin.com/company/creative-code-eu" target="blank" className="pl-2">in</a>
            <a href="https://www.instagram.com/creative_code_tech/" target="blank" className="pl-3">In</a>
          </p>
        </div>
        </div>

      </div>
      {/* <section className="copyright container d-flex flex-row justify-content-between">
        <div className="d-flex"><p>All right reserved • ©2019</p></div>
        <div className="d-flex"><p>Designed by: <a href="https://creative-code.eu/">Creative Code</a></p></div>
      </section> */}
    </footer>
  );
};

export default Footer;
