import * as React from "react";
import { Link } from "gatsby";
import { InferProps } from "prop-types";

export interface IProps {
  toggleModal?: () => void
}

const Menu: React.FC<IProps> = (props) => {
  const {toggleModal} = props;
  return (
    <div className="navbar_menu">
      <ul className="d-flex flex-column flex-sm-row justify-content-between">
        <li onClick={() => !!toggleModal && toggleModal()}><Link to="/">Главная</Link></li>
        <li onClick={() => !!toggleModal && toggleModal()}><Link to="/about">О нас</Link></li>
        <li onClick={() => !!toggleModal && toggleModal()}><Link to="/service">Услуги</Link></li>
        <li onClick={() => !!toggleModal && toggleModal()}><Link to="/contact">Контакты</Link></li>
      </ul>
    </div>
  );
};

export default Menu;
