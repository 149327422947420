import React from "react"
import { Helmet } from "react-helmet"

const Head = () => {
  const currentUrl = typeof window !== "undefined" && window.location.href;
  return (
    <Helmet>
      <meta char="urf-8" />
      <base href="/" />
      <link data-react-helmet="true" rel="canonical" href={currentUrl} />
      <meta property="og:url" content={currentUrl} />

      {/*  Primary Meta Tags */}
      <title>Создание сайтов в Тирасполе</title>
      <meta name="title" content="Создание сайтов в Тирасполе"/>
      <meta name="description" content="Разработка сайтов,  мобильных приложений, CRM систем."/>

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website"/>
      <meta property="og:url" content="https://creative-code.tech/"/>
      <meta property="og:title" content="Создание сайтов в Тирасполе"/>
      <meta property="og:description" content="Разработка сайтов,  мобильных приложений, CRM систем."/>
      <meta property="og:image" content=""/>

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image"/>
      <meta property="twitter:url" content="https://creative-code.tech/"/>
      <meta property="twitter:title" content="Создание сайтов в Тирасполе"/>
      <meta property="twitter:description" content="Разработка сайтов,  мобильных приложений, CRM систем."/>
      <meta property="twitter:image" content="" />

      <meta data-react-helmet="true" property="og:url" name="creative_code" content="creative_code" />
       <link
        data-react-helmet="true"
        rel="apple-touch-icon"
        sizes="64x64"
        href="favicon/icon-64x64.ico"
      />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
  )
}
export default Head