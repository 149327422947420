import * as React from "react";
import Logo from "./Logo"
import Menu from "./Menu"
import MobMenu from "./MobMenu"

const Header: React.FC = () => {
  const [isShow, setShow] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener('scroll', listenToScroll)
  },[])
  const listenToScroll = () => {
    setShow(document.documentElement.scrollTop > 0)
  }
  return (
    <header className={`${isShow ? 'container-fluid' : 'container'} navbar ${isShow ? 'active' : ''} d-flex flex-row py-4 py-sm-5 p-md-4 justify-content-between`}>
      <Logo/>
      <Menu/>
      <MobMenu/>
    </header>
  );
};

export default Header;