import React, { useState, useEffect } from "react"
import Head from "./Head"
import Header from "./Header"
import Footer from "./Footer"
// import "../style/index.scss"

// declare namespace JSX {
//   interface IntrinsicElements {
//     children: any;
//   }
// }

const Layout = ({ children, className = "" }) => {
  const [lang, setLocale] = useState("en")

  // const togglLang = lang => {
  //   setLocale(lang)
  //   typeof document !== `undefined` && localStorage.setItem("lang", lang)
  // }

  // useEffect(() => {
  //   typeof document !== `undefined` &&
  //     setLocale(localStorage.getItem("lang") || "en")
  // }, [])
  return (
    <>
      <Head />
      <div className="App">
        <Header /*togglLang={togglLang} lang={lang}*/ />
        <main className={`container ${className}`}>
          {children}
        </main>
        <Footer />
      </div>
    </>
  )
}

export default Layout